import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('auth_token'));
  const [authError, setAuthError] = useState(null);
  const [fetchAttempts, setFetchAttempts] = useState(0);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  console.log('Using API URL:', API_URL);

  useEffect(() => {
    let isMounted = true;
    
    const initializeAuth = async () => {
      // If we have a token, fetch the user data
      if (token) {
        await fetchUserProfile();
      } else {
        setLoading(false);
      }
    };
    
    initializeAuth();
    
    return () => {
      isMounted = false;
    };
  }, []);  // Empty dependency array to ensure it only runs once

  const fetchUserProfile = async () => {
    // Prevent excessive retries
    if (fetchAttempts >= 2) {
      console.error('Maximum fetch attempts reached. Stopping profile fetching.');
      setAuthError('Unable to connect to the server. Please try logging in again later.');
      setLoading(false);
      return;
    }

    try {
      console.log(`Fetching user profile (attempt ${fetchAttempts + 1} of 3)`);
      setFetchAttempts(prev => prev + 1);
      
      const response = await fetch(`${API_URL}/profile`, {
        method: 'GET',
        headers: {
          'x-auth-token': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      console.log('Profile response status:', response.status);

      if (response.ok) {
        const userData = await response.json();
        console.log('User data received:', userData);
        setCurrentUser(userData);
        setAuthError(null);
        setLoading(false);
      } else {
        // Only logout on authentication errors (401, 403)
        if (response.status === 401 || response.status === 403) {
          console.error('Authentication error:', response.status);
          logout();
          setLoading(false);
        } else {
          console.error('API error:', response.status);
          // Add a delay before retrying
          if (fetchAttempts < 2) {
            console.log(`Will retry after delay (attempt ${fetchAttempts})`);
            setTimeout(() => fetchUserProfile(), 2000 * (fetchAttempts + 1));
          } else {
            setAuthError(`Server error (${response.status}). Please try again later.`);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
      // Network errors shouldn't trigger logout, just set an error state
      // Add a delay before retrying for network errors
      if (fetchAttempts < 2) {
        console.log(`Will retry after delay (attempt ${fetchAttempts})`);
        setTimeout(() => fetchUserProfile(), 2000 * (fetchAttempts + 1));
      } else {
        setAuthError('Network error. Please check your connection and try again.');
        setLoading(false);
      }
    }
  };

  const login = async (email, password) => {
    try {
      // Reset error state and fetch attempts on new login
      setAuthError(null);
      setFetchAttempts(0);
      setLoading(true);
      
      console.log('Attempting login for:', email);
      
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();
      console.log('Login response status:', response.status);

      if (!response.ok) {
        console.error('Login failed:', data.message);
        throw new Error(data.message || 'Login failed');
      }

      console.log('Login successful, token received');
      
      // Store token in localStorage
      localStorage.setItem('auth_token', data.token);
      setToken(data.token);
      setCurrentUser(data.user);
      
      // After setting the token, set loading to false
      setLoading(false);
      
      return data.user;
    } catch (error) {
      console.error('Login error:', error.message);
      setLoading(false);
      throw error;
    }
  };

  const signup = async (displayName, email, password) => {
    try {
      // Reset error state and fetch attempts on signup
      setAuthError(null);
      setFetchAttempts(0);
      setLoading(true);
      
      console.log('Attempting signup for:', email);
      
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          displayName, 
          email, 
          password,
          role: 'commoner' // Default role for new users
        })
      });

      const data = await response.json();
      console.log('Signup response status:', response.status);

      if (!response.ok) {
        console.error('Signup failed:', data.message);
        throw new Error(data.message || 'Signup failed');
      }

      console.log('Signup successful, setting token and user data');
      
      // Instead of calling login(), directly set the user data and token
      localStorage.setItem('auth_token', data.token);
      setToken(data.token);
      setCurrentUser(data.user);
      setLoading(false);
      
      return data.user;
    } catch (error) {
      console.error('Signup error:', error.message);
      setLoading(false);
      throw error;
    }
  };

  const logout = () => {
    console.log('Logging out user');
    localStorage.removeItem('auth_token');
    setToken(null);
    setCurrentUser(null);
    setFetchAttempts(0);
    setAuthError(null);
  };

  // Simplified value object with only what we need for now
  const value = {
    currentUser,
    loading,
    authError,
    login,
    logout,
    signup
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;